import { styled } from 'styled-components';

interface Props {
  width?: string;
  padding?: string;
}

export const DeepPillButton = styled('button')<Props>`
  --gray: #555555;
  --shadow-color: #555555;
  all: unset;
  cursor: pointer;
  border-radius: 90px;
  border: 1px solid var(--gray);
  font-weight: bold;
  background: #fff;

  width: ${props => props.width || 'fit-content'};
  padding: ${props => props.padding || '8px 24px'};

  color: var(--gray);
  box-shadow: 0 1px 0 var(--shadow-color), 0 2px 0 var(--shadow-color), 0 3px 0 var(--shadow-color), 0 3px 0 var(--shadow-color),
    0 4px 0 var(--shadow-color), 0 5px 0 var(--shadow-color);
`;
