import { toast } from 'react-toastify';

/**
 *
 * @param text
 * @link https://hitour2021.whitedb2020.kr/entry/react-toastify-new-line-%EC%A4%84%EB%B0%94%EA%BF%88
 */
export const createToast = (text: string) => {
  toast(text, {
    position: 'top-left',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'colored',
  });
};
