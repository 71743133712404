import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _d from '../../../utils/common/debug';
import { useCountUp, CountUp, ReturnValue } from 'use-count-up';
import ReactGA from '../../../utils/ga4';
import anime from 'animejs/lib/anime.es.js';

import { ReactComponent as ChatBox } from '../../common/chat-box.svg';

const PesteringSection = styled('section')`
  /* background-color: aliceblue; */
  margin-top: 5rem;
  margin-bottom: 3rem;
  padding: 1rem;
  text-align: center;

  line-height: 0.9;
  letter-spacing: -0.6;

  & > p {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #555555;
  }
`;

const PesteringCountNum = styled('h1')`
  margin: 0 auto;
  width: fit-content;
  cursor: pointer;
  font-size: 6rem;
  font-weight: bolder;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  --shadow-color: #555555;
  color: #fff;
  /* -webkit-text-stroke: 1px var(--shadow-color); */
  text-shadow: -1px 0 var(--shadow-color), 0 1px var(--shadow-color), 1px 0 var(--shadow-color), 0 -1px 0 var(--shadow-color),
    0 1px 0 var(--shadow-color), 0 2px 0 var(--shadow-color), 0 3px 0 var(--shadow-color), 0 3px 0 var(--shadow-color), 0 4px 0 var(--shadow-color),
    0 5px 0 var(--shadow-color);
`;

const ChatBoxWrap = styled('div')`
  position: relative;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
`;

const ChatBoxHeart = styled('img')`
  position: absolute;
  width: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 16%));
`;

interface Props {
  count: string | number;
  requestCountUp: any;
}

let ClickTimeout: any = 0; // 클릭 딜레이 타이머 ID
let UserClickCount = 0;
let CountUpStartNum = 0; // countUp 시작할 숫자

export const PesteringCountWrap: React.FC<Props> = ({ count, requestCountUp }) => {
  // 사용자에게만 해당하는 카운트, display용
  const [userCount, setUserCount] = useState(+count);

  useEffect(() => {
    _d.log('Count Changed!', count);
    resetDisplayCount(String(count), userCount);
  }, [count]);

  useEffect(() => {}, []);

  useEffect(() => {}, []);

  const { value, reset } = useCountUp({
    isCounting: true,
    start: CountUpStartNum,
    end: userCount,
    duration: 3,
  });

  const onClickNum = () => {
    UserClickCount++;
    const nowDisplayNum = userCount + 1;
    setUserCount(nowDisplayNum);

    window.clearTimeout(ClickTimeout);
    ClickTimeout = setTimeout(() => {
      _d.log('2동안 클릭하지 않았으므로, 서버에 요청을 보냅니다.', UserClickCount);
      requestCountUp(UserClickCount, (serverCount: string) => {
        resetDisplayCount(serverCount, nowDisplayNum);
      });
    }, 1000);
  };

  const resetDisplayCount = (serverCount: string, nowDisplayNum: number) => {
    _d.log('reset count up from server...');
    CountUpStartNum = nowDisplayNum;
    setUserCount(+serverCount);
    UserClickCount = 0;
    reset();
  };

  anime({
    targets: '.animejs.moving-heart',
    translateY: [0, -16], // from 100 to 250
    delay: 100,
    direction: 'alternate',
    easing: 'cubicBezier(0.000, 0.000, 0.580, 1.000)',
    loop: true,
  });

  return (
    <PesteringSection>
      <ChatBoxWrap className="animejs moving-heart" onClick={onClickNum}>
        <ChatBoxHeart src="/img/heart.png" />
        <ChatBox width={72} height={74} />
      </ChatBoxWrap>
      <PesteringCountNum className="gtm-pestering-count">{value}</PesteringCountNum>
      <p className="font-hipi">명이 디어투 출시를 조르고 있어요 !</p>
      <p className="font-hipi">하트를 눌러주세요</p>
    </PesteringSection>
  );
};
