import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import _d from '../../common/debug';
import ReactGA from '..';

/**
 * uri 변경 추적 컴포넌트
 * uri가 변경될 때마다 pageview 이벤트 전송
 */
export const RouteChangeTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const isGADebugMode = process.env.REACT_APP_GA_DEBUG_MODE === 'true';

  useEffect(() => {
    _d.info('Setup GA4');
    _d.log('GA debug mode: ', isGADebugMode);

    if (!isGADebugMode && !isLocalhost()) {
      _d.info('Init GA4', process.env.REACT_APP_GA_ID);
      ReactGA.initialize(process.env.REACT_APP_GA_ID as string);
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.send('pageview');
    }
  }, [initialized, location]);

  // 개발용
  useEffect(() => {
    if (isGADebugMode) {
      _d.info('Init GA4 by debug mode.', process.env.REACT_APP_GA_ID);
      ReactGA.initialize(process.env.REACT_APP_GA_ID as string);
      ReactGA.set({ page: location.pathname });
      ReactGA.send('pageview');
    }
  }, [location]);

  const isLocalhost = () => {
    return window.location.href.includes('localhost');
  };

  return <></>;
};
