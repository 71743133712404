import styled from 'styled-components';

const ImgIconWrap = styled('div')<any>`
  cursor: ${props => props.cursor || 'inherit'};

  &:img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
`;

interface Props {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  margin?: string;
  onClickFunc?: (e: any) => any;
}

export const ImgIcon: React.FC<Props> = ({ src, alt, width = 'auto', height = 'auto', onClickFunc, margin = 'auto' }) => {
  return (
    <ImgIconWrap onClick={onClickFunc || undefined} cursor={onClickFunc ? 'pointer' : 'inherit'}>
      <img
        src={src}
        alt={alt}
        style={{
          display: 'block',
          width,
          height,
          margin,
        }}
      />
    </ImgIconWrap>
  );
};
