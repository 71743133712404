import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const XYCenterRoot = styled('div')<any>`
  display: flex;
  width: 100vw;
  min-height: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;
  background: ${props => (props.bgColor ? props.bgColor : 'auto')};
  z-index: -1;
`;

interface Props {
  bgColor?: string;
  className?: string;
}

export const XYCenterLayout: React.FC<Props> = ({ bgColor, className }) => {
  return (
    <XYCenterRoot bgColor={bgColor} className={className + ' scroll-hide'}>
      <Outlet />
    </XYCenterRoot>
  );
};
