import { useEffect, useState } from 'react';
import { ToolTip } from '../../components/common/ToolTip';
import RestApi from '../../utils/common/RestApi';
import _d from '../../utils/common/debug';
import { Footer } from '../../components/info/Footer';
import { TopNav } from '../../components/common/nav/TopNav';
import { PesteringCountWrap } from '../../components/cbt/review/PesteringCountWrap';
import { ReviewListWrap } from '../../components/cbt/review/ReviewListWrap';
import { ReviewWriteWrap } from '../../components/cbt/review/ReviewWriteWrap';
import { Head } from '../../components/common/head/Head';

export type CreateReviewDTO = {
  nickname: string;
  content: string;
  paperType: string;
  fontType: string;
};

export const CBTEventPage = (props: any) => {
  const [count, setCount] = useState('0');
  const [reviews, setReviews] = useState<any[]>([]);

  useEffect(() => {
    getCountFromServer();
    getAllReviewsFromServer();

    // setLoopGetCountFromServer();
  }, []);

  const setLoopGetCountFromServer = () => {
    setInterval(() => {
      getCountFromServer();
    }, 10 * 1000);
  };

  const getCountFromServer = async () => {
    const { res } = await RestApi.request('get', '/e/cbt/count');
    setCount(res.data?.count || '0');
  };

  const getAllReviewsFromServer = async () => {
    const { res } = await RestApi.request('get', '/e/cbt/reviews');
    setReviews(res.data?.review);
    // setLoopGetCountFromServer();
  };

  const requestCreateReivew = async (createOpt: CreateReviewDTO, resetInput: () => any) => {
    const { res } = await RestApi.request('post', '/e/cbt/review', createOpt);
    if (res.data) {
      setReviews([res.data].concat(reviews));
      resetInput();
    }
  };

  const requestCountUp = async (num: number, cb: (num: string) => any) => {
    const { res } = await RestApi.request('post', '/e/cbt/count/up', {
      count: num,
    });
    const count = res.data.count;
    setCount(count);
    cb(count);
  };

  return (
    <>
      <TopNav />
      <PesteringCountWrap count={count} requestCountUp={requestCountUp} />
      <ReviewWriteWrap createReview={requestCreateReivew} />
      <ReviewListWrap reviews={reviews} />
      <Footer />
    </>
  );
};
