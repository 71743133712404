import styled from 'styled-components';
import { ReviewPaperBox } from './ReviewPaperBox';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { createRandomNicknameKR } from '../../../utils/common/random-nickname/randomNicknameKR';
import _d from '../../../utils/common/debug';
import { createToast } from '../../../utils/common/toast.util';
import RootData from '../../../constants';
import { CreateReviewDTO } from '../../../page/cbt/CbtEvent.page';
import { FontSelectMenu } from './FontSelectMenu';
import { BgPaperSelectMenu } from './BgPaperSelectMenu';
import { DeepPillButton } from '../../common/btn/DeepButtons';
import { ImgIcon } from '../../common/icon/ImgIcon';

const PesteringSection = styled('section')`
  margin: 1rem 0;
  line-height: 0.9;
  letter-spacing: -0.6;
`;

const ReviewWriteBoxWrap = styled('section')`
  width: fit-content;
  /* width: 300px; */
  margin: 0 auto;
`;

const ReviewWriteBoxHeader = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const ReviewWriteBoxFooter = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`;

const RowStack = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  gap: 1rem;
`;

interface Props {
  createReview: (createOpt: CreateReviewDTO, resetFunc: any) => any;
}

export const ReviewWriteWrap: React.FC<Props> = ({ createReview }) => {
  const [paperType, setPaperType] = useState(RootData.PaperTypes[0]);
  const [fontType, setFontType] = useState(RootData.FontTypes[0]);

  const [content, setContent] = useState('');
  const [nickname, setNickname] = useState('');

  const textareaId = 'ReviewPaperBox';

  useEffect(() => {
    setNickname(createRandomNicknameKR());
  }, []);

  useEffect(() => {}, [content]);

  const resetInput = () => {
    const element = document.getElementById(textareaId);
    _d.log('reset input', element);

    if (element !== null) {
      element.innerHTML = '';
    }

    setContent('');
  };

  const onChangeTextarea = (e: any) => {
    e.preventDefault();
    setContent(e.currentTarget.innerText);
  };

  const onChangePaperType = (e: any) => {
    setPaperType(e.target.value);
  };

  const onChangeFontType = (e: any) => {
    setFontType(e.target.value);
  };

  const onClickDone = (e: any) => {
    e.preventDefault();
    _d.log('content: ', content);

    if (content.length === 0) {
      createToast('내용을 입력해주세요.');
    } else {
      createReview(
        {
          nickname,
          content,
          paperType,
          fontType,
        },
        resetInput,
      );
    }
  };

  const chageToNextPaperType = (e: any, upperNum: 1 | -1) => {
    const maxLegnth = RootData.PaperTypes.length;
    RootData.PaperTypeIndex += upperNum;
    const index = Math.abs(RootData.PaperTypeIndex % maxLegnth);
    setPaperType(RootData.PaperTypes[index]);
  };

  return (
    <PesteringSection>
      {/* 실제 리뷰를 남길 텍스트 박스 */}
      <ReviewWriteBoxWrap>
        {/* <ReviewWriteBoxHeader>
          <BgPaperSelectMenu allTypes={RootData.PaperTypes} paperType={paperType} onChangeFunc={onChangePaperType} />
        </ReviewWriteBoxHeader> */}

        <RowStack>
          <ImgIcon src="/icon/icon-arrow-left.png" alt="" width="20px" onClickFunc={e => chageToNextPaperType(e, -1)} />
          <ReviewPaperBox
            textareaId={textareaId}
            content={content}
            onChange={onChangeTextarea}
            nickname={nickname}
            paperType={paperType}
            fontType={fontType}
          />
          <ImgIcon src="/icon/icon-arrow-right.png" alt="" width="20px" onClickFunc={e => chageToNextPaperType(e, 1)} />
        </RowStack>

        <ReviewWriteBoxFooter style={{ width: 250, margin: '0 auto' }}>
          <FontSelectMenu allTypes={RootData.FontTypes} fontType={fontType} onChangeFunc={onChangeFontType} />
          <DeepPillButton onClick={onClickDone}>send</DeepPillButton>
        </ReviewWriteBoxFooter>
      </ReviewWriteBoxWrap>
    </PesteringSection>
  );
};
