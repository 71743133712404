import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const XCenterRoot = styled('div')<any>`
  padding: 1rem 40px;
`;

interface Props {
  className: string;
}

export const XCenterLayout: React.FC<Props> = ({ className }) => {
  return (
    <XCenterRoot className={className + ''}>
      <Outlet />
    </XCenterRoot>
  );
};
