import ReactGA from '../utils/ga4';

/**
 *
 */
export default class RootData {
  /** 프로젝트 node_env */
  static NodeEnv = process.env.REACT_APP_NODE_ENV || '';

  static IsDebugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

  /**
   * api server host url
   * - `/`로 끝나지 않는다.
   */
  static ApiHost = process.env.REACT_APP_API_URL || 'http://localhost:4000';

  static AppleLoginConfig = {
    clientId: process.env.REACT_APP_APPLE_LOGIN_CLIENT_ID || '',
    redirectUri: process.env.REACT_APP_APPLE_LOGIN_REDIRECT_URI || '',
  };

  static KakaoLoginConfig = {
    jsKey: process.env.REACT_APP_KAKAO_LOGIN_JS_KEY || 'no',
  };

  static PaperTypeIndex = 0;
  static PaperTypes = ['01', '02', '03', '04', '05'];

  static FontTypes = ['font-hipi', 'font-flower', 'font-hana', 'font-min', 'font-lefthand'];

  static HomePageLink = 'https://netstream-library.notion.site/c6bac7ad0ac14e2fb4ef97b57b7af155?pvs=4';

  static DownloadLink = {
    ios: 'https://apps.apple.com/kr/app/dearto/id6455986982',
    aos: null,
  };

  static TobNavLinks = [
    {
      to: 'https://apps.apple.com/kr/app/dearto/id6455986982',
      text: '리뷰 남겨주세요',
    },
    {
      to: 'https://forms.gle/dDn9Rtekyx3pc5WT8',
      text: '1분만에 끝나는 설문조사해주기',
    },
  ];

  /** */
  static FooterData = {
    Links: [
      {
        text: '디어투 바로가기',
        href: RootData.HomePageLink,
      },
      {
        text: '디어투 다운로드 (IOS)',
        href: RootData.DownloadLink.ios,
        preFunc: () => {
          ReactGA.event('app_download_btn_click', { value: 'app-store' });
        },
      },
      {
        text: 'linktree',
        href: '/linktree',
      },
    ],
    CompanyInfo: [
      {
        title: '사업자 등록번호',
        desc: '306-87-01683',
      },
      {
        title: '주소 : 본점',
        desc: '충남 아산시 배방읍 희망로 46번길 45-7 창조빌딩 511호',
      },
      {
        title: '주소 : 연구소',
        desc: '서울 금천구 디지털로 9길 41 삼성IT해링턴타워 1017호',
      },
      {
        title: '메일',
        desc: 'info@netstream.co.kr',
      },
      {
        title: '전화',
        desc: '02-6013-0603',
      },
    ],
  };

  static LinkTreeData = [
    {
      text: 'Download (IOS)',
      href: RootData.DownloadLink.ios,
      preFunc: () => {
        ReactGA.event('app_download_btn_click', { value: 'app-store' });
      },
    },
    {
      text: '디어투 홈페이지',
      href: RootData.HomePageLink,
    },
    {
      text: '디어투 정식출시 조르기',
      href: '/',
    },
    {
      text: '디어투 서포터즈 1기 신청하기',
      href: 'https://forms.gle/rKVNWuzRps6K4g2f7',
    },
    {
      text: '디어투 서포터즈 1기 안내 사항',
      href: '/supporters',
    },
    {
      text: '1분만에 끝나는 설문조사해주기',
      href: 'https://forms.gle/dDn9Rtekyx3pc5WT8',
    },
  ];
}
