import React from 'react';
import styled from 'styled-components';
import ReactGA from '../../ga4';

const DownloadBtn = styled('a')<any>`
  img {
    height: ${props => props.height || 'inherit'};
    width: ${props => props.width || 'inherit'};
    margin: ${props => props.margin || 'inherit'};
  }
`;

interface Props {
  width?: string;
  height?: string;
  href?: string;
  margin?: string;
}

/**
 *
 * @returns
 * @link https://developer.apple.com/licensing-trademarks/
 */
export const AppStoreDownloadBtn: React.FC<Props> = ({ href = '#', width, height, margin }) => {
  return (
    <DownloadBtn
      tabIndex={0}
      href={href}
      width={width}
      height={height}
      margin={margin}
      target="_blank"
      onClick={(e: any) => ReactGA.event('app_download_btn_click', { value: 'app-store' })}
    >
      <img src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" alt="download on the app store" />
    </DownloadBtn>
  );
};
