import { Link } from 'react-router-dom';
import styled from 'styled-components';
import RootData from '../../constants';
import { info } from 'console';
import { getComponentKey } from '../../utils/common/text.util';

const FooterRoot = styled('div')`
  width: 100%;
  margin-top: 200px;
  padding: 1rem 0;
  color: #b8b8b8;
  font-size: 0.8rem;
`;

const FooterHeader = styled('h1')`
  padding: 1rem 0;
`;

const FooterBody = styled('h1')`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  align-items: flex-start;
`;

const CompanyInfoList = styled('dl')`
  a {
    color: inherit;
    font-style: normal;
    text-decoration: none;
    margin: 0.3rem 0;
    display: block;
  }
  a:hover {
    text-decoration: underline;
  }
`;

const CompanyInfoItem = styled('div')`
  display: flex;
  margin: 0.3rem 0;
  dt {
    min-width: 120px;
  }
`;

const FooterLink = styled('a')`
  color: inherit;
  font-style: normal;
  text-decoration: none;
  margin: 0.3rem 0;
  display: block;
  &:hover {
    text-decoration: underline;
  }
`;

export const Footer = () => {
  return (
    <FooterRoot>
      <FooterHeader>(주) 넷스트림</FooterHeader>
      <FooterBody>
        <CompanyInfoList>
          {RootData.FooterData.Links?.map(link => (
            <FooterLink
              key={getComponentKey()}
              href={link.href}
              onClick={(e: any) => {
                if (link.preFunc) link.preFunc();
              }}
            >
              {link.text}
            </FooterLink>
          ))}
        </CompanyInfoList>

        <CompanyInfoList>
          {RootData.FooterData.CompanyInfo?.map(info => (
            <CompanyInfoItem key={getComponentKey()}>
              <dt>{info.title}</dt>
              <dd>{info.desc}</dd>
            </CompanyInfoItem>
          ))}
        </CompanyInfoList>
      </FooterBody>
    </FooterRoot>
  );
};
