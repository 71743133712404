import styled from 'styled-components';
import { ReviewPaperBox } from './ReviewPaperBox';
import { getComponentKey } from '../../../utils/common/text.util';
import RootData from '../../../constants';
import { ReviewReplyList } from '../review-reply/ReviewReplyList';
import { useEffect } from 'react';

import StackGrid from 'react-stack-grid';

const ReviewListRoot = styled('div')`
  margin: 5rem 0;
  width: 100%;
  min-height: 90%;
  /* display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row; */
  /* gap: 2rem; */
`;

const ReviewPaperWrap = styled('div')``;

const EmptyText = styled('h3')`
  color: #717171;
`;

interface Props {
  reviews: any[];
}

export const ReviewListWrap: React.FC<Props> = ({ reviews }) => {
  useEffect(() => {}, []);
  return (
    <ReviewListRoot>
      <StackGrid columnWidth={250} duration={500} gutterHeight={32} gutterWidth={32}>
        {reviews?.length ? (
          reviews.map(review => {
            return (
              <ReviewPaperWrap key={getComponentKey()}>
                <ReviewPaperBox
                  key={getComponentKey()}
                  content={review.content}
                  nickname={review.nickname}
                  paperType={review.paperType}
                  fontType={review.fontType || RootData.FontTypes[0]}
                  editable={false}
                  debugString={RootData.IsDebugMode ? `${review.reviewId}` : ''}
                />
                <ReviewReplyList replies={review.reply} />
              </ReviewPaperWrap>
            );
          })
        ) : (
          <EmptyText>첫번째 메모를 남겨주세요!</EmptyText>
        )}
      </StackGrid>
    </ReviewListRoot>
  );
};
