import { styled } from 'styled-components';
import { TextLink, TextLinkProps } from './TextLink';
import { getComponentKey } from '../../../utils/common/text.util';

const LinkListRoot = styled('ul')`
  margin-top: 1rem;
`;

const LinkListItemRoot = styled('li')`
  margin: 1rem 0;
  font-size: 0.9rem;
`;

interface Props {
  links: TextLinkProps[];
}

export const LinkList: React.FC<Props> = ({ links }) => {
  return (
    <LinkListRoot>
      {links.map(link => (
        <LinkListItemRoot key={getComponentKey()}>
          <TextLink to={link.to} text={link.text} />
        </LinkListItemRoot>
      ))}
    </LinkListRoot>
  );
};
