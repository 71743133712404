import { styled } from 'styled-components';

const FontMenuWrap = styled('div')`
  display: flex;
  gap: 5px;
`;

const FontMenuItem = styled('div')`
  & > input {
    display: none;
  }

  & > label {
    --shadow-color: #555555;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 30px;

    position: relative;
    cursor: pointer;

    border: 1px solid var(--shadow-color);
    background: #8b8b8b;
    box-shadow: 0 1px 0 var(--shadow-color), 1px 2px 0 var(--shadow-color),
      2px 3px 0 var(--shadow-color);

    span {
      font-size: 0.8rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & > input:checked + label {
    background: #fff;
  }
`;

interface Props {
  allTypes: string[];
  fontType: string;
  onChangeFunc: (e: any) => any;
}

export const FontSelectMenu: React.FC<Props> = ({
  allTypes,
  fontType,
  onChangeFunc,
}) => {
  return (
    <FontMenuWrap>
      {allTypes.map((typeCode) => {
        return (
          <FontMenuItem key={`font-type-${typeCode}`}>
            <input
              type='radio'
              id={`font-type-${typeCode}`}
              name='font-type-radio'
              value={typeCode}
              onChange={onChangeFunc}
              checked={typeCode === fontType}
            />
            <label htmlFor={`font-type-${typeCode}`}>
              <span className={typeCode}>가</span>
            </label>
          </FontMenuItem>
        );
      })}
    </FontMenuWrap>
  );
};
