import styled from 'styled-components';
import { Head } from '../../components/common/head/Head';

const NotFoundHeadText = styled('h1')`
  font-size: 3rem;
`;

export const NotFoundPage = (props: any) => {
  return (
    <>
      <Head title="dearto" subTitle="404" />
      <NotFoundHeadText>Sorry... Not Found...</NotFoundHeadText>
    </>
  );
};
