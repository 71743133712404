export default class _d {
  static _name = 'Dearto';
  static _time = 0;
  static _level = '';

  static config(option: { name: string; level: string }) {
    this._name = option.name;
    this._level = option.level || '';
  }

  static setLevel(str: any) {}

  static getDiffTime() {
    let exTime = this._time;
    let now = new Date().getTime();
    let diff = exTime ? now - this._time : 0;
    this._time = new Date().getTime();
    return ` +${diff}ms:`;
  }

  static log(...args: any[]) {
    if (!this._level.match('debug')) return;
    let css = `color: #2067e3`;
    let diff = this.getDiffTime();
    console.log(this._name + '%c[debug]' + diff, css, ...args);
  }

  static info(...args: any[]) {
    if (!this._level.includes('info')) return;
    let css = `color: #e8ad17`;
    let diff = this.getDiffTime();
    console.log(this._name + '%c[info]' + diff, css, ...args);
  }

  static error(...args: any[]) {
    if (!this._level.includes('error')) return;
    let css = `color: #eb441a`;
    let diff = this.getDiffTime();
    console.log(this._name + '%c[error]' + diff, css, ...args);
  }

  static notice(...args: any[]) {
    if (!this._level.includes('notice')) return;
    let css = `color: #18ad31`;
    let diff = this.getDiffTime();
    console.log(this._name + '%c[notice]' + diff, css, ...args);
  }
}
