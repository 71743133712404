import styled from 'styled-components';
import { createToast } from '../../common/toast.util';
import ReactGA from '../../ga4';

const DownloadBtn = styled('a')<any>`
  display: block;
  img {
    width: ${props => props.width || 'inherit'};
    height: ${props => props.height || 'inherit'};
    margin: ${props => props.margin || 'inherit'};
  }
`;

interface Props {
  width?: string;
  height?: string;
  href?: string | null;
  margin?: string;
  nullText?: string;
}
/**
 *
 * @returns
 * @link https://developer.apple.com/licensing-trademarks/
 */
export const GooglePlayStoreDownloadBtn: React.FC<Props> = ({ href, width, height, margin, nullText }) => {
  const onClickNotYet = (e: any) => {
    if (!href || href === '#') {
      e.preventDefault();
      nullText && createToast(nullText);
    }

    ReactGA.event('app_download_btn_click', { value: 'play-store' });
  };
  return (
    <DownloadBtn tabIndex={0} href={href || '#'} width={width} height={height} margin={margin} target="_blank" onClick={onClickNotYet}>
      <img src="/icon/google-play-badge.png" alt="google-play-badge" />
    </DownloadBtn>
  );
};
