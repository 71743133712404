import React, { useEffect } from 'react';

import './reset.css';
import './index.css';
import './fonts.css';

import { useLocation, useNavigate, useParams, useRoutes } from 'react-router-dom';
import { ROUTES } from './routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _d from './utils/common/debug';
import { RouteChangeTracker } from './utils/ga4/components/RouteChangeTracker';
import ReactGA from './utils/ga4';

function App() {
  const routes = useRoutes(ROUTES);

  useEffect(() => {
    _d.info('Setup App');
    _d.config({
      name: 'Dearto',
      level: 'debug,info,notice,error',
    });

    // GA4 유저 정보 세팅하기
    // ReactGA.set({
    //   user_properties: {
    //     user_uid: '1',
    //     user_birthyear: '2000',
    //     user_gender: '2',
    //   },
    // });
  }, []);

  return (
    <>
      <ToastContainer />
      <RouteChangeTracker />
      {routes}
    </>
  );
}

export default App;
