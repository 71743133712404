import styled from 'styled-components';
import { AppStoreDownloadBtn } from '../../utils/apple/components/AppStoreDownloadBtn';
import { GooglePlayStoreDownloadBtn } from '../../utils/google/components/GooglePlayStoreDownloadBtn';
import RootData from '../../constants';
import { Head } from '../../components/common/head/Head';

const DownloadSection = styled('section')`
  padding: 1rem;
`;

const Stack = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const DownloadPage = () => {
  return (
    <>
      <Head title="notion" subTitle="download" />
      <DownloadSection>
        <Stack>
          <AppStoreDownloadBtn width="160px" href={RootData.DownloadLink.ios} />
          <GooglePlayStoreDownloadBtn
            width="160px"
            href={RootData.DownloadLink.aos}
            nullText={'안드로이드 서비스 준비중입니다.\n조금만 기다려주세요!'}
          />
        </Stack>
        {/* <Footer /> */}
      </DownloadSection>
    </>
  );
};
