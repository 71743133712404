import styled from 'styled-components';
import { ImgIcon } from '../../components/common/icon/ImgIcon';
import { LoginIconsNav } from '../../components/common/nav/LoginIconsNav';
import { DeepPillButton } from '../../components/common/btn/DeepButtons';
import { useLocation, useNavigate } from 'react-router-dom';
import RootData from '../../constants';
import { Head } from '../../components/common/head/Head';

const LinkTreeRoot = styled('div')`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const LinkTreeHeader = styled('div')`
  margin-bottom: 2rem;
`;

const LinkTreeBody = styled('div')``;

const LinkTreeList = styled('ul')``;

const LinkTreeItem = styled('li')`
  margin-bottom: 1rem;
`;

export const LinktreePage = (props: any) => {
  const moveToPage = (href: string, preFunc?: () => any) => {
    if (preFunc) preFunc();
    window.open(href, '_self');
  };

  return (
    <>
      <Head title="dearto" subTitle="linktree" />
      <LinkTreeRoot>
        <LinkTreeHeader>
          <ImgIcon src="/img/logo-heart-purple.png" alt="" width="60px" />
          <ImgIcon src="/img/logo.png" alt="" width="100px" />
        </LinkTreeHeader>
        <LinkTreeBody>
          <LinkTreeList className="font-hana">
            {RootData.LinkTreeData.map(data => (
              <LinkTreeItem>
                <DeepPillButton width="160px" padding="12px 24px" onClick={e => moveToPage(data.href, data.preFunc)}>
                  {data.text}
                </DeepPillButton>
              </LinkTreeItem>
            ))}
          </LinkTreeList>
        </LinkTreeBody>
      </LinkTreeRoot>
    </>
  );
};
