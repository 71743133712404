import { CBTEventPage } from './page/cbt/CbtEvent.page';
import { XCenterLayout } from './layouts/XCenter.layout';
import { HomePage } from './page/home/Home.page';
import { DownloadPage } from './page/download/Download.page';
import { XYCenterLayout } from './layouts/XYCenter.layout copy';
import { NotFoundPage } from './page/system/NotFound.page';
import { LinktreePage } from './page/linktree/Linktree.page';
import { SupportersPage } from './page/cbt/supporters/Supporters.page';

export const ROUTES = [
  {
    path: '/',
    element: <XCenterLayout className={'bg-paper'} />,
    children: [{ index: true, element: <CBTEventPage />, name: 'Home', title: '', subTitle: '' }],
  },
  {
    path: '/',
    element: <XYCenterLayout bgColor={'#fff'} />,
    children: [{ path: 'download', element: <DownloadPage />, name: 'null', title: '', subTitle: '' }],
  },
  {
    path: '/',
    element: <XYCenterLayout className={'bg-paper'} />,
    children: [
      { path: 'linktree', element: <LinktreePage />, name: 'null', title: '', subTitle: '' },
      { path: 'supporters', element: <SupportersPage />, name: 'null', title: '', subTitle: '' },
    ],
  },
  {
    path: '/',
    element: <XYCenterLayout />,
    children: [{ index: true, path: '*', element: <NotFoundPage />, name: 'null', title: '', subTitle: '' }],
  },
];
