import styled from 'styled-components';
import RootData from '../../../constants';
import { useEffect } from 'react';

const ReviewWriteBox = styled('div')`
  /* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.09)); */
  width: 250px;
  height: 250px;

  margin: 0 auto;

  padding: 1.5rem 1rem;
  padding-bottom: 2rem;
  position: relative;

  & > img {
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: contain;
  }
`;

const ReviewTextareaWrap = styled('div')`
  padding: 0 0.5rem;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  display: grid;
  justify-content: center;
  align-items: center;
  gap: 0px;

  &::-webkit-scrollbar {
    /* ( 크롬, 사파리, 오페라, 엣지 ) 동작 */
    display: none;
  }

  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;

const ReviewTextareaBox = styled('div')`
  width: 100%;
  /* height: 100%; */
  /* max-height: 100%; */

  text-align: center;
  white-space: pre-wrap;
  line-break: anywhere;

  opacity: 1;
  font-size: 1.2rem;
  line-height: 1.3;

  &[placeholder]:empty::before {
    content: attr(placeholder);
    opacity: 0.5;
    font-size: 1rem;
  }

  &[placeholder]:empty:focus::before {
    content: '';
  }

  &[placeholder]:focus {
    outline: none;
  }
`;

const ReviewWriterName = styled('p')`
  padding: 0.2rem 0;
  font-size: 0.7rem;
  font-weight: light;
  text-align: right;
  opacity: 0.3;
`;

const ReviewDebug = styled('div')`
  position: absolute;
  font-size: 0.8rem;
  top: 0.5rem;
  left: 0.5rem;
  color: blue;
`;

interface Props {
  textareaId?: string;
  nickname?: string;
  editable?: boolean;
  content: string;
  onChange?: (e: any) => any;
  paperType?: string;
  fontType?: string;
  debugString?: string;
}

export const ReviewPaperBox: React.FC<Props> = ({
  textareaId = '',
  nickname,
  paperType = RootData.PaperTypes[0],
  content = '',
  editable = true,
  onChange,
  fontType = RootData.FontTypes[0],
  debugString,
}) => {
  return (
    <ReviewWriteBox>
      <ReviewDebug>{debugString}</ReviewDebug>
      <ReviewTextareaWrap className={fontType}>
        <ReviewTextareaBox
          id={editable ? textareaId : ''}
          onInput={editable ? onChange : undefined}
          contentEditable={editable}
          placeholder={'디어투에 대한 의견이나 리뷰,\n따뜻한 응원의 말을 남겨주세요!'}
          suppressContentEditableWarning={true}
        >
          {!editable ? content : ''}
        </ReviewTextareaBox>
      </ReviewTextareaWrap>

      <ReviewWriterName>{nickname}</ReviewWriterName>
      <img src={`/img/bg-${paperType}.png`} alt="" />
    </ReviewWriteBox>
  );
};
