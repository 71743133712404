export const AdjectiveKR = [
  '츄르먹는',
  '예쁜',
  '귀여운',
  '사랑스러운',
  '매력적인',
  '화려한',
  '자유로운',
  '신나는',
  '따뜻한',
  '환상적인',
  '맥주마시는',
  '춤추는',
  '하품하는',
  '웃는',
  '뛰는',
  '무뚝뚝한',
  '부끄러운',
  '고요한',
  '날렵한',
  '놀라는',
  '자랑스러운',
  '노래하는',
  '밝은',
  '긴장하는',
  '느긋한',
  '깜짝놀라는',
  '먹보',
  '커피마시는',
  '깔끔한',
  '신비로운',
  '유쾌한',
  '따분한',
  '차분한',
  '용감한',
  '소심한',
  '더운',
  '추운',
  '바쁜',
  '느릿느릿한',
  '가벼운',
  '무거운',
  '깨끗한',
  '더러운',
  '부드러운',
  '딱딱한',
  '빛나는',
  '침착한',
  '긴박한',
  '부드러운',
  '거친',
  '자상한',
  '성실한',
  '신중한',
  '무심한',
  '날카로운',
  '둔한',
  '무뚝뚝한',
  '부끄러운',
  '즐거운',
  '미묘한',
  '나른한',
  '낙관적인',
  '비관적인',
  '지루한',
  '열정적인',
  '예의바른',
  '무례한',
  '마음이 큰',
  '속마음 깊은',
  '멋진',
  '훌륭한',
  '소심한',
  '배부른',
  '고단한',
  '우울한',
  '시끄러운',
  '조용한',
  '활기찬',
  '지친',
  '행복한',
  '불행한',
  '친절한',
  '냉정한',
  '완벽한',
  '흐린',
  '맑은',
  '불안한',
  '평온한',
  '기운찬',
  '피곤한',
  '무력한',
  '강한',
  '약한',
  '날카로운',
  '둔한',
  '촉촉한',
  '거대한',
  '작은',
  '큰',
  '깊은',
  '얕은',
  '빠른',
  '느린',
  '감각적인',
  '무관심한',
  '세련된',
  '예측불가한',
  '단호한',
  '미끈미끈한',
  '상냥한',
  '열정어린',
  '기분 좋은',
  '울적한',
  '멋진',
  '똑똑한',
  '바보같은',
  '자유분방한',
  '감사한',
  '설레는',
  '짜릿한',
  '지혜로운',
  '무지한',
  '깊은',
  '가벼운',
  '경쾌한',
  '비싼',
  '저렴한',
  '유익한',
  '무용한',
  '풍부한',
  '가난한',
  '훈훈한',
  '근엄한',
  '섬세한',
  '강인한',
  '허약한',
  '즐거운',
  '불행한',
  '낙천적인',
  '비관적인',
  '발랄한',
  '어두운',
  '밝은',
  '컴퓨터 하는',
  '고민하는',
  '방방뛰는',
  '창밖을 내려다보는',
  '생각에 잠긴',
  '바람을 쐬는',
  '마음을 열고 듣는',
  '영감을 얻는',
  '색다른 경험을 하는',
  '빈둥빈둥 떠는',
  '시선을 끄는',
  '동경하는',
  '휴식을 취하는',
  '열심히 일하는',
  '도전적인',
  '무한한 가능성을 가진',
  '다양한 생각을 하는',
  '엉뚱한 아이디어를 낸',
  '마음 속에 미소를 품은',
  '새로운 길을 찾는',
  '과거를 회상하는',
  '미래를 꿈꾸는',
  '인생의 뜻을 찾는',
  '남다른 관점을 가진',
  '일상에 감동하는',
  '멋진 이야기를 나누는',
  '자신감 넘치는',
  '눈부신 미소를 띈',
  '하늘을 날아다니는',
];
