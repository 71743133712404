export const AnimalNameKR = [
  '고양이',
  '강아지',
  '두더지',
  '앵무새',
  '친칠라',
  '사자',
  '호랑이',
  '곰',
  '여우',
  '늑대',
  '토끼',
  '다람쥐',
  '너구리',
  '말',
  '소',
  '돼지',
  '쥐',
  '햄스터',
  '고슴도치',
  '원숭이',
  '판다',
  '코끼리',
  '기린',
  '얼룩말',
  '수달',
  '오랑우탄',
  '다람이',
  '악어',
  '북극곰',
  '사막여우',
  '하이에나',
  '낙타',
  '오리',
  '독수리',
  '펭귄',
  '다이노',
  '해마',
  '바다사자',
  '고래',
  '상어',
  '바다거북',
  '갈매기',
  '매',
  '개구리',
  '도마뱀',
  '비둘기',
  '오소리',
  '해파리',
  '자라',
  '토둘',
  '오라클',
  '아나콘다',
  '반달곰',
  '바나나',
  '불곰',
  '햄벅',
  '힙포',
  '냥스',
  '돌고래',
  '핑크 돌고래',
  '테디',
  '샤르',
  '피글릿',
  '스폰지',
  '해골',
  '카우',
  '라마',
  '악당',
  '유니콘',
  '단무지',
  '공주님',
  '땅콩',
  '유나',
  '젤리',
  '호두',
  '마늘',
  '후추',
  '장미',
  '해바라기',
  '개나리',
  '청산벽',
  '진달래',
  '금잔화',
  '매화',
  '나팔꽃',
  '해바라기',
  '국화',
  '튤립',
  '라일락',
  '카네이션',
  '장미',
  '클로버',
  '산호',
  '진주',
  '독개구리',
  '미모사',
  '말벌',
  '토끼',
  '노비',
  '시바견',
  '왕자',
  '심바',
  '말티즈',
  '얼룩말',
  '요정',
  '몬스터',
  '여신',
  '눈사람',
  '베어',
  '달마시안',
  '해파리',
  '사이버',
  '신',
  '첫사랑',
  '유령',
  '큐피드',
  '페이스',
  '용사님',
  '모험가',
  '루나',
  '힐러',
  '삼색 고양이',
  '댕댕이',
  '치즈냥',
  '푸들',
  '허스키',
  '바퀴벌레',
  '무당벌레',
  '말티즈',
];
