import styled from 'styled-components';
import { AppleCircleLogo } from '../../../utils/apple/components/AppleCircleLogo';
import { ToolTip } from '../ToolTip';
import anime from 'animejs/lib/anime.es.js';
import _d from '../../../utils/common/debug';
import RootData from '../../../constants';
import { LoginUserProfile } from '../LoginUserProfile';
import { KakaoLogin } from '../../../utils/kakao/KakaoLogin.util';
import { AppleLogin } from '../../../utils/apple/AppleLogin.util';
import { createToast } from '../../../utils/common/toast.util';
import { KakakoLoginBtn } from '../../../utils/kakao/components/KakaoLoginBtn';
import { AppleSignInBtn } from '../../../utils/apple/components/AppleSignInBtn';
import { KakaoCircleLogo } from '../../../utils/kakao/components/KakaoCircleLogo';
import { useEffect, useState } from 'react';
import { Kakao } from '../../../utils/kakao';
import { LoginManager } from '../../../utils/common/loginManager.util';
import ReactGA from '../../../utils/ga4';
import QueryString from 'qs';
import { Link, useLocation } from 'react-router-dom';
import { LinkList } from '../link/LinkList';

const NavRoot = styled('div')`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const NavLeft = styled('div')`
  width: fit-content;
`;

const NavCenter = styled('div')`
  flex: 1;
`;

const NavRight = styled('div')`
  width: fit-content;
`;

const LogoImgWrap = styled('a')`
  display: block;
  height: 1rem;
  img {
    height: 100%;
  }
  /* 2rem */
`;

const DownloadBtn = styled('a')`
  color: #555;
  border-bottom: 1px solid #555;
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none;
  outline: none;
  &:hover,
  &:active {
    text-decoration: none;
    color: #555;
  }
`;

export const TopNav = () => {
  anime({
    targets: '.aminjs.tooltip',
    translateY: [0, 16], // from 100 to 250
    delay: 300,
    direction: 'alternate',
    loop: true,
  });

  useEffect(() => {}, []);

  return (
    <NavRoot className={'parent-target'}>
      <NavLeft>
        <LogoImgWrap href={RootData.HomePageLink} target={'_blank'}>
          <img src="/img/logo.png" alt="logo" />
        </LogoImgWrap>
        <LinkList links={RootData.TobNavLinks} />
      </NavLeft>

      <NavCenter></NavCenter>
      <NavRight>
        <DownloadBtn href={RootData.DownloadLink.ios}>Pre-Download!</DownloadBtn>
      </NavRight>

      <>
        {/* <ToolTip className="aminjs tooltip" text={'💜 미리 회원가입 해두실 수 있어요!'} sx={{ bottom: '-3rem', right: '0px' }} child={true} /> */}
      </>
    </NavRoot>
  );
};
