import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../../routes';

interface Props {
  title?: string;
  subTitle?: string;
}

export const Head: React.FC<Props> = ({ title = '', subTitle = '' }) => {
  const defaultTitle = process.env.REACT_APP_DEFAULT_TITLE || 'dearto - 디어투';

  useEffect(() => {
    setHeadTitle();
  }, [title, subTitle]);

  const setHeadTitle = () => {
    window.document.title = getTitle(title, subTitle); // title 적용
  };

  const getTitle = (title: string, subTitle: string) => {
    return title + (subTitle ? ` - ${subTitle}` : '') || defaultTitle;
  };

  return <></>;
};
