import { styled } from 'styled-components';
import { ReviewReplyListItem } from './ReviewReplyListItem';
import { ImgIcon } from '../../common/icon/ImgIcon';

const ReviewReplyrListRoot = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
  max-width: 250px;
`;
const ReviewReplyrListWrap = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

interface Props {
  replies: any[];
}

export const ReviewReplyList: React.FC<Props> = ({ replies }) => {
  const isEmptyArr = (arr: any) => {
    return !replies || !Array.isArray(replies) || arr?.length === 0;
  };
  return (
    <>
      {!isEmptyArr(replies) && (
        <ReviewReplyrListRoot>
          <ImgIcon src="/icon/icon-arrow-top-to-botton.png" alt="" width="1rem" height="fit-content" />
          <ReviewReplyrListWrap>
            {replies?.map(reply => (
              <ReviewReplyListItem reply={reply} key={reply.reviewId} />
            ))}
          </ReviewReplyrListWrap>
        </ReviewReplyrListRoot>
      )}
    </>
  );
};
