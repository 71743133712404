import styled from 'styled-components';
import { ImgIcon } from '../../../components/common/icon/ImgIcon';
import RootData from '../../../constants';
import { DeepPillButton } from '../../../components/common/btn/DeepButtons';
import { Link } from 'react-router-dom';
import { Head } from '../../../components/common/head/Head';

const InfoTextBody = styled('div')`
  text-align: left;
  line-height: 1.4;

  margin: 100px 0;

  h1,
  h2,
  h3 {
    font-weight: bold;
    margin-bottom: 0.8rem;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.2rem;
  }
`;

const InfoTextHeader = styled('div')`
  text-align: center;
  margin: 2.5rem auto;
`;

const InfoParagraph = styled('div')`
  margin: 2.5rem;
`;

const SubDesc = styled('p')`
  font-size: 0.75rem;
  color: #4e4e4e;
  margin-top: 0.3rem;
`;

const SubDescSpan = styled('span')`
  font-size: 0.75rem;
  color: #4e4e4e;
  margin-top: 0.3rem;
`;

export const SupportersPage = (props: any) => {
  const moveToPage = (href: string) => {
    window.open(href, '_blank');
  };
  return (
    <>
      <Head title="dearto" subTitle="supporters" />
      <InfoTextBody>
        <InfoTextHeader>
          <h1 className="font-hana">💞 디어투 서포터즈 1기 모집중 💞</h1>
          <p>⌜ 특별한 사람과의 특별한 추억 ⌟</p>
        </InfoTextHeader>

        <InfoParagraph>
          <p>디어투의 서포터즈가 되어</p>
          <p>특별한 사람과의 특별한 추억을 쌓아보세요.</p>
        </InfoParagraph>

        <InfoParagraph>
          <p>우리만의 아지트, 하이틴 영화속 나만의 캐비닛은 덤! </p>
        </InfoParagraph>

        <InfoParagraph>
          <h2>🍒 이런분을 찾습니다.</h2>
          <p>- 친구들과 앱으로 소통하는 것을 좋아하는 사람</p>
          <p>- 새로운 형태의 SNS에 관심이 많은 사람</p>
          <p>
            <br />
            위에 해당하는 누구나 참여 가능해요!
          </p>
        </InfoParagraph>
        <InfoParagraph>
          <h2>🍒 활동 안내</h2>
          <p>활동 기간: 9.27 ~ 10.4 (약 1주간) </p>
          <p>➊ 디어투를 통해 가족/친구와 추억 쌓기</p>
          <p>➋ SNS를 통해 추억 자랑하기</p>
          <p>➌ 사용 설문조사 참여하기</p>
          <SubDesc>* 모든 활동은 비대면 온라인으로 진행됩니다.</SubDesc>
        </InfoParagraph>
        <InfoParagraph>
          <h2>🍒 혜택</h2>
          <p>
            - 모든 미션 완료시 활동비 2만원 상당의 상품권 지급 <SubDescSpan>(올리브영, 스타벅스, GS25 택1)</SubDescSpan>
          </p>
          <p>
            - 우수 활동팀 상품 증정 <SubDescSpan>(귀여운 쪽지를 자랑하시면 우수 활동팀 당첨 확률 up~!)</SubDescSpan>
          </p>
          <SubDesc>* 자세한 안내사항은 합격자들에게 별도의 pdf로 전송됩니다 </SubDesc>
        </InfoParagraph>
        <InfoParagraph>
          <h2>🍒 지원 방법</h2>
          <p>지원하기 버튼 클릭!</p>
          <p>신청 기간: 9.18 ~ 9.22</p>
          <p>합격 발표: 9.25</p>
        </InfoParagraph>

        <InfoParagraph>
          <h3>✦ 유의 사항</h3>
          <p>- SNS 채널은 제한이 없으며, 팀별 콘텐츠 1건 이상 업로드 해야합니다.</p>
          <p>- 디어투 기능 활용한 지속적인 소통을 해야합니다. (주 3회이상 쪽지)</p>
          <p>- 마지막 설문조사에 참여하셔야 혜택을 받으실 수 있습니다.</p>
        </InfoParagraph>

        <InfoParagraph>
          <h3>✦ 문의사항</h3>
          <p>- 인스타그램 DM (@dearto_official)</p>
          <p>- 카카오 플러스친구 (@dearto)</p>
        </InfoParagraph>

        <div style={{ textAlign: 'center' }}>
          <DeepPillButton onClick={e => moveToPage('https://forms.gle/rKVNWuzRps6K4g2f7')} className="font-hana" width={'120px'}>
            지원하기
          </DeepPillButton>
        </div>
      </InfoTextBody>
    </>
  );
};
