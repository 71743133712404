import { styled } from 'styled-components';

const ReviewReplyRoot = styled('div')`
  white-space: break-spaces;
  font-size: 1.1rem;
  color: #191919;
`;

const ReviewReplyWriterName = styled('h3')`
  width: fit-content;
  margin-bottom: 0.2rem;
  border-bottom: 1px solid black;
`;

const ReviewReplyContent = styled('p')``;

interface Props {
  reply: {
    reviewId: number;
    pid?: number;
    memId?: number;
    status: string;
    paperType: string;
    fontType: string;
    nickname: string;
    content: string;
    createDate?: string;
    updateDate?: string;
  };
}

export const ReviewReplyListItem: React.FC<Props> = ({ reply }) => {
  return (
    <ReviewReplyRoot className="font-hana">
      <ReviewReplyWriterName>{reply.nickname}</ReviewReplyWriterName>
      <ReviewReplyContent>{reply.content}</ReviewReplyContent>
    </ReviewReplyRoot>
  );
};
