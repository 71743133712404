import { Link } from 'react-router-dom';
import styled from 'styled-components';

const TextLinkRoot = styled(Link)`
  --a-default-color: black;
  text-decoration: none;
  color: var(--a-default-color);
  border-bottom: 1px solid var(--a-default-color);

  position: relative;

  &::before {
    background: hsl(45 100% 70%);
    content: '';
    inset: 0;
    position: absolute;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease-in-out;
    z-index: -1;
  }

  &:hover::before {
    transform: scaleX(1);
    transform-origin: left;
  }
`;

export interface TextLinkProps {
  to: string;
  text: string;
  ariaLabel?: string;
}

export const TextLink: React.FC<TextLinkProps> = ({ to = '#', text }) => {
  return (
    <TextLinkRoot to={to} aria-label={''}>
      {text}
    </TextLinkRoot>
  );
};
