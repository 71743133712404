import RootData from '../../constants';
import _d from './debug';
import axios from 'axios';
import { createToast } from './toast.util';

axios.defaults.withCredentials = true;

export default class RestApi {
  static _header: any = {};

  static header(key: string, value: any) {
    this._header[key] = value;
    return this;
  }

  static async request(method = 'GET', url = '/', body: any = null) {
    try {
      _d.log('Request URL    : ', `${method} ${RootData.ApiHost}${url}`);
      _d.log('Request Header : ', this._header);
      const response = await axios({
        method: method,
        withCredentials: true,
        url: RootData.ApiHost + url,
        data: body,
        headers: {
          ...this._header,
        },
      });

      _d.log('Response Headers AT : ', response.headers['access_token']);
      _d.log('Response Headers    : ', response.headers);
      _d.log('Response : ', response.data);

      return { res: response.data, headers: response.headers };
    } catch (error: any) {
      let errorData = error.response?.data;
      createToast('Sorry... Network Error');
      throw errorData || error;
    }
  }
}
